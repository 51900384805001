<template>
	<div class="fill-height">
		<router-view />
		<v-dialog v-model="dialog" max-width="400">
			<v-card>
				<v-card-title class="primary pa-3 pl-4" persistent max-width="400">
					<span class="sub-title-2 white--text font-weight-regular">{{ $tc('auth.Login.p_recovery', 1) }}</span>
					<v-spacer/>
					<v-btn small icon dark @click="dialog = false ; pwd = ''; pwdc = ''">
						<v-icon size="20">mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text class="pt-4">
					<v-alert :value="error" type="error" dense text class="mt-2 sub-title-2 rounded-lg">
						{{ error_message }}
					</v-alert>
					<Label :value="$tc('auth.Login.password', 1)" />
					<TextField v-model="pwd" type="password" clearable class="mb-4 mt-1" />
					<Label :value="$tc('auth.Login.password_confirm', 1)" />
					<TextField v-model="pwdc" type="password" clearable class="mt-1" />
				</v-card-text>
				<v-card-actions class="pb-4 px-6 pt-2">
					<v-spacer />
					<v-btn
							class="px-6 rounded-lg"
							:disabled="pwd === '' || pwdc === '' || pwd === null || pwdc === null"
							color="primary"
							@click="sendPasswordRequest()"
							:loading="loading"
					>
						<div class="paragraph font-weight-bold">{{ $tc('global.action.send', 1) }}</div>
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	export default {
		name: "Auth",
		components: {
			Label: () => import('@/components/ui/Label.vue'),
			TextField: () => import('@/components/fields/TextField.vue')
		},
		data(){
			return {
				dialog: false,
				error: false,
				error_message: "",
				pwd: "",
				pwdc: "",
				loading: false
			}
		},
		methods: {
			sendPasswordRequest(){
				if(this.pwd === this.pwdc){
					this.loading = true
					this.$wsc.post("user/resetpassword", {token: this.$route.query.token, password: this.pwd, password_confirm: this.pwdc}, success => {
						this.loading = false
						this.dialog = false
						this.$store.dispatch('snackbar/success', 'Your password has been changed successfully')
						this.$router.push('/auth')
					}, fail => {
						this.loading = false
						this.dialog = false
						this.$router.push('/auth')
					})
				}
				else {
					this.error = true
					this.error_message = 'Wrong confirmation password'
				}
			}
		},
		created() {
			if(this.$route.query.token){
				this.dialog = true
			}

			if(this.$store.getters['auth/contact_id']){
				if(this.$store.getters.redirect){
					this.$router.push(this.$store.getters.redirect)
				}
				else {
					this.$router.push('/dashboard')
				}
			}
		}
	}
</script>

<style scoped>

</style>